import React, { useState } from "react"
import PropTypes from "prop-types"
import { Accordion } from "react-bootstrap"
import Card from "./card"
import "./cards.scss"

const Cards = ({ cards, isAccordion }) => {
  const [activeIndex, setActiveIndex] = useState("")

  const toggleCardHandler = index => () => {
    setActiveIndex(prevIndex => (prevIndex === index ? "" : index))
  }

  return (
    <Accordion className="g-accordion-cards">
      {cards.map((card, i) => (
        <Card
          {...card}
          activeIndex={activeIndex}
          index={i.toString()}
          isAccordion={isAccordion}
          key={i}
          toggleCardHandler={toggleCardHandler}
        />
      ))}
    </Accordion>
  )
}

Cards.props = {
  cards: PropTypes.arrayOf(PropTypes.shape(Card.props)),
}

Cards.propTypes = Cards.props

export default Cards
