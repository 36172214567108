import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { useForm, Controller } from "react-hook-form"
import { useMediaPredicate } from "react-media-hook"
import _ from "lodash"
import { navigate } from "gatsby"
import PhoneInput from "react-phone-input-2"
import DatePicker from "react-datepicker"
import ReCAPTCHA from "react-google-recaptcha"
import slugify from "slugify"
import Select from "react-select"
import { getSelectOptions, getFieldReference, encode } from "./utils"
import { INPUT_FIELDS } from "./constants"
import TextEditor from "components/blocks/textEditor"
import { CallToActionProps } from "components/blocks/callToAction"
import InputField from "components/elements/inputField"
import "./form.scss"

const SITE_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY || null

const Form = ({
  description,
  disclaimer,
  formTitleColor,
  inputFields,
  subject,
  submitAction,
  title,
  withBackground,
}) => {
  const {
    control,
    errors,
    formState,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm()
  const { isSubmitting } = formState
  const isMobile = useMediaPredicate("(max-width: 769px)")
  const recaptchaRef = useRef({})
  const [submitButtonStatus, setSubmitButtonStatus] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState()
  const [birthdate, setBirthdate] = useState()

  const handlePhoneChange = value => {
    setPhoneNumber(value)
  }

  const onSubmit = values => {
    setSubmitButtonStatus(true)

    if (!values.childsBirthdate) values.childsBirthdate = birthdate

    return fetch("/", {
      body: encode({
        "form-name": slugify(title),
        "g-recaptcha-response": recaptchaRef.current.getValue(),
        subject: `New Form Submission - ${subject} Form`,
        ...values,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST",
    })
      .then(() => navigate(submitAction.action.action))
      .catch(error => {
        setSubmitButtonStatus(false)
        alert(error)
      })
  }

  const handleChange = (name, data) => {
    setValue(name, data)
  }

  const renderInputTextField = (inputField, i) => {
    switch (slugify(inputField.type)) {
      case "Rich-Text":
        return (
          <div className="text-editor mb-5" key={i}>
            <InputField
              errors={errors}
              id={_.camelCase(inputField.label)}
              label={inputField.label}
              name={_.camelCase(inputField.label)}
              placeholder={inputField.placeholder}
              reference={register(getFieldReference(inputField))}
              type="hidden"
            />
            <TextEditor
              handleChange={data =>
                handleChange(_.camelCase(inputField.label), data)
              }
            />
          </div>
        )

      case "date-picker":
        if (birthdate && !watch(_.camelCase(inputField.label)))
          handleChange(_.camelCase(inputField.label), birthdate)

        return (
          <div className="field-wrapper" key={i}>
            <InputField
              errors={errors}
              id={_.camelCase(inputField.label)}
              label={inputField.label}
              name={_.camelCase(inputField.label)}
              placeholder={inputField.placeholder}
              reference={register(getFieldReference(inputField))}
              type="hidden"
            />
            {isMobile ? (
              <input
                onChange={value => {
                  const selectedDate = new Date(
                    value.target.value + " 00:00:00"
                  )
                  const date = `${
                    selectedDate.getMonth() + 1
                  }/${selectedDate.getDate()}/${selectedDate.getFullYear()}`
                  handleChange(_.camelCase(inputField.label), date)
                  setBirthdate(date)
                }}
                type="date"
              />
            ) : (
              <DatePicker
                onChange={value => {
                  const date = `${
                    value.getMonth() + 1
                  }/${value.getDate()}/${value.getFullYear()}`
                  handleChange(_.camelCase(inputField.label), date)
                  setBirthdate(date)
                }}
                placeholderText="Select date..."
                selected={Date.parse(birthdate)}
                showMonthDropdown
                showYearDropdown
                value={birthdate}
              />
            )}
          </div>
        )

      case "phone-number":
        return (
          <div className="phone-wrapper" key={i}>
            <label className="phone-label" htmlFor="phone-input">
              {inputField.label + (inputField.required ? " *" : "")}
            </label>
            <Controller
              as={
                <PhoneInput
                  country="us"
                  disableSearchIcon
                  enableSearch
                  id="phone-input"
                  inputClass="phone-input"
                  inputProps={{
                    name: _.camelCase(inputField.label),
                    required: inputField.required,
                  }}
                  onChange={handlePhoneChange}
                  placeholder="Phone Number"
                  ref={register(getFieldReference(inputField))}
                  value={phoneNumber}
                />
              }
              control={control}
              defaultValue={inputField.required ? "1" : ""}
              name={_.camelCase(inputField.label)}
              rules={getFieldReference(inputField)}
            />
            <p className="invalid-feedback d-block mb-0 mt-1">
              {errors[_.camelCase(inputField.label)]?.message}
            </p>
          </div>
        )

      default:
        return (
          <InputField
            count={inputField.maximumCount}
            errors={errors}
            id={_.camelCase(inputField.label)}
            key={i}
            label={inputField.label + (inputField.required ? " *" : "")}
            name={_.camelCase(inputField.label)}
            placeholder={inputField.placeholder}
            reference={register(getFieldReference(inputField))}
            type={inputField.type}
          />
        )
    }
  }

  const renderInputField = (inputField, i) => {
    switch (inputField.__typename) {
      case INPUT_FIELDS.Text:
        return renderInputTextField(inputField, i)

      case INPUT_FIELDS.SideBySide:
        return (
          <div key={i} style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "15px", width: "50%" }}>
              {renderInputField(inputField.inputFields[0], i)}
            </div>
            <div style={{ marginLeft: "15px", width: "50%" }}>
              {renderInputField(inputField.inputFields[1], i)}
            </div>
          </div>
        )

      case INPUT_FIELDS.Select:
        return (
          <div className="field-wrapper" key={i}>
            <div className="lable">
              {inputField.label + (inputField.required ? " *" : "")}
            </div>
            <Controller
              control={control}
              defaultValue=""
              name={_.camelCase(inputField.label)}
              onChange={([selected]) => selected.value}
              render={({ onChange, ref, value }) => (
                <Select
                  inputRef={ref}
                  label={inputField.label}
                  name={_.camelCase(inputField.label)}
                  onChange={value => onChange(value.value)}
                  options={getSelectOptions(
                    inputField.source,
                    inputField.options
                  )}
                  placeholder="Choose..."
                  ref={register}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: theme.colors,
                  })}
                  value={value.value}
                />
              )}
              rules={getFieldReference(inputField)}
            />
            <p className="invalid-feedback d-block mb-0 mt-1">
              {errors[_.camelCase(inputField.label)]?.message}
            </p>
          </div>
        )

      case INPUT_FIELDS.Hidden:
        return (
          <input
            className={inputField.class}
            data-fieldname={inputField.dataFieldName}
            id={inputField.fieldId}
            key={i}
            name={inputField.name || _.camelCase(inputField.title)}
            ref={register}
            type="hidden"
          />
        )

      default:
        return null
    }
  }

  return (
    <div className="col-lg-6 col-md-12 m-auto" id={slugify(title)}>
      <div className={withBackground ? "White_bg" : ""} id="Form">
        <div
          className="headline"
          style={{ color: formTitleColor ? formTitleColor : "#7a4d8b" }}
        >
          {title}
        </div>
        <div className="sub-headline">
          {description && (
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
                  ? description.childMarkdownRemark.html
                  : description.content
                  ? description.content
                  : description.description,
              }}
            />
          )}
        </div>
        <div className="form">
          <form
            action={submitAction.action.action}
            autoComplete="off"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
            method="post"
            name={slugify(title)}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <input name="form-name" type="hidden" value={slugify(title)} />
            <input
              defaultValue={`New Form Submission - ${subject} Form`}
              name="subject"
              readOnly
              type="hidden"
            />
            <fieldset>
              <legend>Caregiver and Patient Information</legend>
              {inputFields
                .filter(
                  field =>
                    field.label &&
                    (field.label.includes("Caregiver") ||
                      field.label.includes("Patient"))
                )
                .map((field, i) => renderInputField(field, i))}
            </fieldset>
            <fieldset>
              <legend>Referring Provider&apos;s Information</legend>
              {inputFields
                .filter(
                  field =>
                    field.label &&
                    (field.label.includes("Referring Provider") ||
                      field.label.includes("Nearest Cortica Center") ||
                      field.label.includes("Reason for Referral"))
                )
                .map((field, i) => renderInputField(field, i))}
            </fieldset>
            {SITE_KEY && (
              <ReCAPTCHA
                className="mb-3 recaptcha-left"
                onChange={() => setSubmitButtonStatus(false)}
                onErrored={() => setSubmitButtonStatus(true)}
                onExpired={() => setSubmitButtonStatus(true)}
                ref={recaptchaRef}
                sitekey={SITE_KEY}
              />
            )}
            <button
              className="primary-btn form-submit-button"
              disabled={submitButtonStatus}
              type="submit"
            >
              {isSubmitting ? "Submitting..." : submitAction.buttonText}
            </button>
            <div>
              {disclaimer && (
                <div
                  className="disclaimer"
                  dangerouslySetInnerHTML={{
                    __html: disclaimer?.childMarkdownRemark?.html,
                  }}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const FieldBaseProps = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
}

const SelectFieldProps = {
  ...FieldBaseProps,
  options: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string,
}

const TextFieldProps = {
  ...FieldBaseProps,
  maximumCount: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

const HiddenFieldProps = {
  ...FieldBaseProps,
  class: PropTypes.string,
  dataFieldName: PropTypes.string,
  fieldId: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
}

const SideBySideFields = (...args) =>
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(HiddenFieldProps),
      PropTypes.shape(SelectFieldProps),
      PropTypes.shape(TextFieldProps),
      PropTypes.shape({
        ...FieldBaseProps,
        inputFields: SideBySideFields,
      }),
    ])
  )

const SideBySideFieldProps = {
  ...FieldBaseProps,
  inputFields: SideBySideFields,
}

export const FormProps = {
  description: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
    content: PropTypes.string,
    description: PropTypes.string,
  }),
  disclaimer: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  formTitleColor: PropTypes.string,
  id: PropTypes.string,
  inputFields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(HiddenFieldProps),
      PropTypes.shape(SelectFieldProps),
      PropTypes.shape(SideBySideFieldProps),
      PropTypes.shape(TextFieldProps),
    ])
  ),
  subject: PropTypes.string,
  submitAction: PropTypes.shape(CallToActionProps),
  title: PropTypes.string,
  withBackground: PropTypes.bool,
}

Form.propTypes = FormProps

export default Form
