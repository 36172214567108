import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import TextLockup from "components/elements/textLockup"
import Layout from "components/blocks/layout"
import Button from "components/elements/styledButton"
import Cards from "./cards"
import "./accordion.scss"

const Accordion = ({
  action,
  cards,
  className,
  intro,
  isAccordion,
  layout,
}) => (
  <Layout
    actionContent={
      <Button
        {...(action || {})}
        color={(action && action.color) || "yellow"}
      />
    }
    className={renderClasses("g-accordion", [[className, className]])}
    layout={layout}
    textLockup={intro}
  >
    <Cards cards={cards} isAccordion={isAccordion} />
  </Layout>
)

Accordion.props = {
  ...Cards.props,
  action: PropTypes.shape(Button.props),
  className: PropTypes.string,
  layout: PropTypes.string,
  intro: PropTypes.shape(TextLockup.props),
}

Accordion.propTypes = Accordion.props

export default Accordion
