import React from "react"
import PropTypes from "prop-types"
import SECTIONS from "utils/sectionConstants"
import CirclesSection, {
  CirclesSectionProps,
} from "components/blocks/circlesSection"
import ParagraphList, {
  ParagraphListProps,
} from "components/blocks/paragraphList"
import RichText, { RichTextProps } from "components/elements/richText"
import Form, { FormProps } from "components/blocks/form"
import "./sideBySide.scss"

const SideBySide = ({ customClass, sections, subtitle, title }) => {
  const renderContent = (section, index) => {
    switch (section.__typename) {
      case SECTIONS.ContentfulSectionRichText:
        return (
          <div className="quote col-lg-6 col-md-12" key={index}>
            <RichText {...section} />
          </div>
        )

      case SECTIONS.Form:
        return <Form {...section} key={index} />

      // Dynamic Sections
      default:
        switch (section.style) {
          case "Circles":
            return <CirclesSection {...section} key={index} />

          default:
            return section.paragraphs || section.actions ? (
              <div
                className="col-lg-4 col-md-12 paragraphList-mobile-view"
                key={index}
              >
                <ParagraphList {...section} />
              </div>
            ) : null
        }
    }
  }

  return (
    <div className={`section ${customClass}`} id="SideBySideSection">
      <div className="container">
        {title && (
          <div className="header">
            {title && <h2 className="headline careModel">{title}</h2>}
            {subtitle && <h2 className="sub-headline">{subtitle}</h2>}
          </div>
        )}
        <div className="row justify-content-center align-center">
          {sections.map((section, index) => renderContent(section, index))}
        </div>
      </div>
    </div>
  )
}

export const SideBySideProps = {
  __typename: PropTypes.string,
  className: PropTypes.string,
  customClass: PropTypes.string,
  name: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(CirclesSectionProps),
      PropTypes.shape(FormProps),
      PropTypes.shape(ParagraphListProps),
      PropTypes.shape(RichTextProps),
    ])
  ),
  style: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

SideBySide.propTypes = SideBySideProps

export default SideBySide
