import React from "react"
import PropTypes from "prop-types"
import "./textList.scss"

const TextList = ({ items, layout }) => {
  const renderList = (item, index) => {
    switch (layout) {
      case "horizontal":
        return (
          <div className="col-md-3 col-sm-6 col-12" key={index}>
            <li className="item horizontal">
              <img
                alt={item.icon.title}
                className="mb-3"
                height="60"
                src={item.icon.file.url}
                width="60"
              />
              <h2 className="item-horizontal-title">{item.title}</h2>
              <p className="item-horizontal-desc">
                {item.description.description}
              </p>
            </li>
          </div>
        )

      default:
        return (
          <li className="item" key={index}>
            <h2 className="item-title">{item.title}</h2>
            <p className="item-desc">{item.description.description}</p>
          </li>
        )
    }
  }

  return (
    <ul className={`careModelDescription p-0 mt-5 ${layout}`}>
      <div className="row">
        {items.map((item, index) => renderList(item, index))}
      </div>
    </ul>
  )
}

export const TextListProps = {
  alignment: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  icon: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  title: PropTypes.string,
}

TextList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(TextListProps)),
  layout: PropTypes.string,
}

export default TextList
