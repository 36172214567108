import React, { useState } from "react"
import PropTypes from "prop-types"
import Accordion, { AccordionProps } from "components/blocks/accordion"
import "./familyJourney.scss"

const StepsTabs = ({ cards }) => {
  const [activeTab, setActiveTab] = useState(cards[0].id)

  return (
    <div className="text-center" id="family-journey-wrapper">
      <div className="family-journey-desktop">
        <ul className="tabs-wrapper w-75 m-auto">
          {cards.map((tab, index) => (
            <li
              className={`${activeTab === tab.id && "active"}`}
              key={index}
              onClick={() => setActiveTab(tab.id)}
              onKeyDown={() => setActiveTab(tab.id)}
              role="presentation"
            >
              <p className="w-100 mb-1 step">{tab.headline}</p>
              <p className="step-content">{tab.subheadline}</p>
            </li>
          ))}
        </ul>
        <div className="row no-gutters">
          <div className="col-md-9 col-12 m-auto">
            {cards
              .filter(item => item.id === activeTab)
              .map(filterdItem => (
                <div
                  className="tab-content"
                  dangerouslySetInnerHTML={{
                    __html: filterdItem.content.childMarkdownRemark.html,
                  }}
                  key={filterdItem.id}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="family-journey-mobile">
        <Accordion cards={cards} onClick={id => setActiveTab(id)} />
      </div>
    </div>
  )
}

StepsTabs.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(AccordionProps)),
}

export default StepsTabs
