import React from "react"
import PropTypes from "prop-types"
import CKEditor from "ckeditor4-react"

const TextEditor = ({ handleChange }) => (
  <CKEditor
    config={{
      removeButtons: "",
      removePlugins: "resize",
      toolbar: [
        {
          items: ["Bold", "Italic", "Underline"],
          name: "basicstyles",
        },
        {
          items: ["Link", "Unlink"],
          name: "links",
        },
      ],
    }}
    data=""
    onChange={e => handleChange(e.editor.getData())}
    onDialogDefinition={e => console.info(e.data)}
  />
)

TextEditor.propTypes = {
  handleChange: PropTypes.func,
}

export default TextEditor
