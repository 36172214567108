import React from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import Accordion, { AccordionProps } from "components/blocks/accordion"
import StepsTabs from "components/sections/familyJourney"

const AccordionList = ({ data }) => {
  const renderList = () => {
    switch (slugify(data.style)) {
      case "Accordion":
        return <Accordion {...data} />

      case "Tab":
        return <StepsTabs {...data} />

      default:
        return null
    }
  }

  return (
    <div className="d-flex justify-content-center pb-5" id="accordionList">
      <div className="col-md-8 col-12 m-auto">{renderList()}</div>
    </div>
  )
}

export const AccordionListProps = {
  cards: PropTypes.arrayOf(PropTypes.shape(AccordionProps)),
  id: PropTypes.string,
  style: PropTypes.string,
}

AccordionList.propTypes = {
  data: PropTypes.shape(AccordionListProps),
}

export default AccordionList
