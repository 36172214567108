import React from "react"
import PropTypes from "prop-types"
import DoctorDetailedSection from "components/blocks/doctorDetailedSection"
import "./doctors.scss"

const Doctors = ({ doctors, title }) => {
  return (
    <section className="clinic-team-section">
      <div className="clinic-team-title container">
        <h2>{title}</h2>
      </div>
      <div className="doctors-section container">
        {doctors.map((doctor, i) => (
          <DoctorDetailedSection {...doctor} key={i} />
        ))}
      </div>
    </section>
  )
}
export default Doctors

Doctors.propTypes = {
  doctors: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.shape({
        json: PropTypes.object,
      }),
      name: PropTypes.string,
      picture: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
        }),
        title: PropTypes.string,
      }),
      role: PropTypes.string,
      type: PropTypes.shape({
        type: PropTypes.string,
      }),
    })
  ),
  title: PropTypes.string,
}
