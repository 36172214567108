import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import "./circle.scss"

const CircleList = ({ list = [], path }) => (
  <ul className="corticaCareModel list">
    {list?.map((item, index) => (
      <li
        className="item"
        key={index}
        onClick={() => navigate(path)}
        onKeyDown={() => navigate(path)}
        role="presentation"
        style={{ borderColor: item.style }}
      >
        <span className={`icon ${item.class}`}>
          <img alt={item.name} src={item.icon?.file?.url} />
        </span>
        <span className="text">{item.name}</span>
      </li>
    ))}
  </ul>
)

CircleList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      class: PropTypes.string,
      icon: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
      style: PropTypes.string,
    })
  ),
  path: PropTypes.string,
}

export default CircleList
