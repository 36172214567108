import React from "react"
import PropTypes from "prop-types"
import "./squareCard.scss"

const SquareCard = ({ card, index }) => (
  <div className={`squareCard_${index + 1}`} id="squareCard">
    <img alt={card.icon?.title} src={card.icon?.file?.url} />
    <h3 className="title">{card.name}</h3>
  </div>
)

SquareCard.propTypes = {
  card: PropTypes.shape({
    icon: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  index: PropTypes.number,
}

export default SquareCard
