import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import SECTIONS from "utils/sectionConstants"
import Headline, { HeadlineProps } from "components/elements/headline"
import RichText, { RichTextProps } from "components/elements/richText"
import ExternalVideo, {
  ExternalVideoProps,
} from "components/elements/externalVideo"
import SideBySide, { SideBySideProps } from "components/sections/sideBySide"
import Form, { FormProps } from "components/blocks/form"
import QuoteSection, {
  QuoteSectionProps,
} from "components/sections/quoteSection"
import ActionsList, { ActionsListProps } from "components/sections/actionsList"
import AccordionList, {
  AccordionListProps,
} from "components/blocks/accordionList"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import TextSection, { TextSectionProps } from "components/sections/textSection"
import SectionList, { SectionListProps } from "components/sections/sectionList"
import ParagraphList, {
  ParagraphListProps,
} from "components/blocks/paragraphList"
import "./wrapper.scss"
import COMPONENTS from "utils/componentConstants"
import SplitSection from "../splitSection"
import Faq from "../faq"
import Doctors from "../doctors/doctors"
import Button from "components/elements/styledButton"

const Wrapper = ({
  action,
  backgroundImage,
  backgroundStyle,
  className,
  wrapperSections,
}) => {
  const backgroundFluidImageStack = [
    backgroundImage?.fluid,
    `linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.55) 34%,
      rgba(255, 255, 255, 0) 67%
    )`,
  ].reverse()
  const getAlignmentFromSection = section => {
    if (section.__typename === SECTIONS.ContentfulSectionRichText) {
      return section.alignment || "left"
    }
    return "center"
  }
  const renderSection = (section, index) => {
    switch (section.__typename) {
      case SECTIONS.WrapperSection:
        return <Wrapper {...section} key={index} />

      case COMPONENTS.SplitContent:
        return <SplitSection {...section} key={index} />

      case COMPONENTS.Faq:
        return <Faq {...section} key={index} />

      case SECTIONS.SideBySide:
        return <SideBySide {...section} key={index} />

      case COMPONENTS.Doctors:
        return <Doctors {...section} key={index} />

      case SECTIONS.ContentfulSectionRichText:
        return (
          <RichText
            {...section}
            className={className}
            key={index}
            textStyle={className === "full" ? "full" : null}
          />
        )

      case SECTIONS.Form:
        return <Form {...section} key={index} withBackground />

      case SECTIONS.SectionQuote:
        return <QuoteSection {...section} key={index} />

      case SECTIONS.SectionListOfActions:
        return <ActionsList {...section} key={index} />

      case SECTIONS.Headline:
        return <Headline {...section} key={index} />

      case SECTIONS.ListOfAccordionCards:
        return <AccordionList data={section} key={index} />

      case SECTIONS.CallToAction:
        return (
          <CallToAction
            className="w-100 text-center"
            data={section}
            key={index}
            type="button"
          />
        )

      case SECTIONS.TextSection:
        return <TextSection {...section} key={index} type={section.style} />

      case SECTIONS.VideoWrapper:
        return (
          <div className="center w-100" key={index}>
            <ExternalVideo {...section} />
          </div>
        )

      case SECTIONS.SectionList:
        return <SectionList {...section} key={index} />

      case SECTIONS.ParagraphsList:
        return <ParagraphList {...section} key={index} />

      default:
        return null
    }
  }

  return (
    <div className={`${className}`} id="Wrapper">
      {backgroundImage ? (
        <BackgroundImage fluid={backgroundFluidImageStack}>
          {wrapperSections?.map((section, index) =>
            renderSection(section, index)
          )}
          {action && (
            <div
              style={{
                textAlign: wrapperSections?.length
                  ? getAlignmentFromSection(wrapperSections[0])
                  : "center",
              }}
            >
              <Button {...action} className={"wrapper-button"} />
            </div>
          )}
        </BackgroundImage>
      ) : (
        <div className="d-flex">
          <div className={backgroundStyle}>
            {wrapperSections?.map((section, index) =>
              renderSection(section, index)
            )}
            {action && (
              <div
                style={{
                  textAlign: wrapperSections?.length
                    ? getAlignmentFromSection(wrapperSections[0])
                    : "center",
                }}
              >
                <Button {...action} className={"wrapper-button"} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const BaseProps = {
  action: PropTypes.shape(Button.props),
  backgroundImage: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  backgroundStyle: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
}

const WrapperSections = (...args) =>
  PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(AccordionListProps),
      PropTypes.shape(ActionsListProps),
      PropTypes.shape(CallToActionProps),
      PropTypes.shape(ExternalVideoProps),
      PropTypes.shape(FormProps),
      PropTypes.shape(HeadlineProps),
      PropTypes.shape(ParagraphListProps),
      PropTypes.shape(QuoteSectionProps),
      PropTypes.shape(RichTextProps),
      PropTypes.shape(SectionListProps),
      PropTypes.shape(SideBySideProps),
      PropTypes.shape(TextSectionProps),
      PropTypes.shape({
        ...BaseProps,
        wrapperSection: WrapperSections,
      }),
    ])
  )(...args)

export const WrapperProps = {
  ...BaseProps,
  wrapperSections: WrapperSections,
}

Wrapper.propTypes = WrapperProps

export default Wrapper
