import React from "react"
import PropTypes from "prop-types"
import Card from "./card"
import "./sectionList.scss"

const SectionList = ({ icon, listOptions, numberOfColumns, style, title }) => {
  switch (style) {
    case "Purple text with gradient background":
      return (
        <div className="section text-center" id="ServicesSection">
          <div className="container">
            {title && <h2 className="headline">{title}</h2>}
            <div className="row justify-content-center">
              {listOptions?.map((card, index) => (
                <div
                  className={`col-lg-${12 / numberOfColumns} col-md-6 col-12`}
                  key={index}
                >
                  <Card content={card} icon={icon?.file?.url} key={index} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )

    case "Black text with plain background":
      return (
        <div className="container section-p-b">
          <div className="row justify-content-center">
            <div className="col-md-9 col-12">
              <div className="row">
                {listOptions?.map((card, index) => (
                  <div
                    className={`col-lg-${12 / numberOfColumns} col-md-6 col-12`}
                    key={index}
                  >
                    <Card
                      content={card}
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #b9c6cf",
                        boxShadow: "0 2px 6px 0 rgba(185, 185, 185, 0.21)",
                        color: "$darkBlue",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )

    case "Text":
      return (
        <ul className="check-list">
          {listOptions?.map((item, index) => (
            <li className="icon-check" key={index}>
              {item}
            </li>
          ))}
        </ul>
      )

    default:
      return (
        <ul>
          {listOptions?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )
  }
}

export const SectionListProps = {
  icon: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  listOptions: PropTypes.arrayOf(PropTypes.string),
  numberOfColumns: PropTypes.number,
  style: PropTypes.string,
  title: PropTypes.string,
}

SectionList.propTypes = SectionListProps

export default SectionList
