import React from "react"
import PropTypes from "prop-types"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import "./actionsList.scss"

const ActionsList = ({ actions }) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-9 col-12">
        <div className="actions-tabs d-flex justify-content-between">
          {actions.map((action, i) => (
            <div className="tab mr-2" key={i}>
              <CallToAction className="tabs-link" data={action} type="link" />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export const ActionsListProps = {
  actions: PropTypes.arrayOf(PropTypes.shape(CallToActionProps)),
  id: PropTypes.string,
}

ActionsList.propTypes = ActionsListProps

export default ActionsList
