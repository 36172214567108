import React, { useState } from "react"
import PropTypes from "prop-types"
import "./inputField.scss"

const InputField = ({
  count = "",
  errors,
  id = "",
  isAddressInput = true,
  label = "",
  name = "",
  onChange,
  placeholder = "",
  reference,
  type = "text",
  value,
}) => {
  const [inputLen, setInputLen] = useState(0)

  return (
    <div className="form-input form-group">
      <label htmlFor={id}>{label}</label>
      <input
        className="form-control"
        id={id}
        maxLength={count}
        name={name}
        onChange={
          isAddressInput ? onChange : e => setInputLen(e.target.value.length)
        }
        placeholder={placeholder}
        ref={reference}
        type={type}
        value={isAddressInput ? value : ""}
      />
      {count && (
        <p
          className={
            "text-right " + (inputLen === count ? "text-danger" : "text-muted")
          }
        >
          {inputLen}/{count}
        </p>
      )}
      {errors && (
        <p className="invalid-feedback d-block mb-0 mt-1">
          {errors[name] && errors[name].message}
        </p>
      )}
    </div>
  )
}

InputField.propTypes = {
  count: PropTypes.number,
  errors: PropTypes.shape({
    name: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  id: PropTypes.string,
  isAddressInput: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  reference: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
}

export default InputField
