import React from "react"
import PropTypes from "prop-types"
import { useStaticQueryGetTreatmentList } from "services/graphQl/queries/treatmentList"
import CircleList from "components/blocks/circleList"
import SquareCard from "components/blocks/squareCard"
import "./circlesSection.scss"

const CirclesSection = ({ slug }) => {
  const treatmentList = useStaticQueryGetTreatmentList()

  return (
    <>
      <div className="col-lg-6 col-md-12 section-desktop-view">
        <CircleList list={treatmentList} path={slug} />
      </div>
      {treatmentList.map((item, Index) => (
        <div className="col-6 section-mobile-view" key={Index}>
          <SquareCard card={item} index={Index} />
        </div>
      ))}
    </>
  )
}

export const CirclesSectionProps = {
  slug: PropTypes.string,
}

CirclesSection.propTypes = CirclesSectionProps

export default CirclesSection
