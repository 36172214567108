import React from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import "./textSection.scss"

const TextSection = ({
  body,
  headline,
  style,
  subHeadline,
  textSectionActions,
  title,
}) => (
  <div className={`section text-center ${slugify(style)}`} id={slugify(title)}>
    <div className="container">
      {slugify(title) === "Make-an-Appointment-Section" && (
        <img
          alt="brain-icon-purple"
          className="icon-mob"
          src="/images/brain-icon-purple.svg"
        />
      )}
      {headline && <h2 className="headline">{headline}</h2>}
      {subHeadline && <p className="sub-headline">{subHeadline}</p>}
      {body && (
        <div
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        />
      )}
      {textSectionActions?.map((item, index) => (
        <CallToAction
          ctaWrapper="d-flex justify-content-center"
          data={item}
          key={index}
          type="button"
        />
      ))}
    </div>
  </div>
)

export const TextSectionProps = {
  body: PropTypes.shape({
    body: PropTypes.string,
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  headline: PropTypes.string,
  style: PropTypes.string,
  subHeadline: PropTypes.string,
  textSectionActions: PropTypes.arrayOf(PropTypes.shape(CallToActionProps)),
  title: PropTypes.string,
}

TextSection.propTypes = TextSectionProps

export default TextSection
