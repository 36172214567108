import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetTreatmentList = () => {
  const query = useStaticQuery(
    graphql`
      query TreatmentList {
        allContentfulTreatment {
          nodes {
            name
            slug
            style
            description {
              description
            }
            icon {
              title
              file {
                url
              }
            }
          }
        }
      }
    `
  )
  return query.allContentfulTreatment.nodes
}
