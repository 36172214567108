import React from "react"
import PropTypes from "prop-types"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import TextList, { TextListProps } from "components/blocks/textList"

const ParagraphList = ({ actions, layout, paragraphs }) => (
  <>
    {paragraphs && <TextList items={paragraphs} layout={layout} />}
    {actions?.map((item, index) => (
      <CallToAction
        ctaWrapper="d-flex justify-content-center"
        data={item}
        key={index}
        type="button"
      />
    ))}
  </>
)

export const ParagraphListProps = {
  actions: PropTypes.arrayOf(PropTypes.shape(CallToActionProps)),
  layout: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.shape(TextListProps)),
  title: PropTypes.string,
}

ParagraphList.propTypes = ParagraphListProps

export default ParagraphList
