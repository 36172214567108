import React from "react"
import PropTypes from "prop-types"
import { Accordion, Card as Item } from "react-bootstrap"
import Heading4 from "components/type/Heading4"
import Wysiwyg from "components/type/Wysiwyg"
import "./card.scss"

const Card = ({
  activeIndex,
  index,
  isAccordion,
  text,
  title,
  toggleCardHandler,
}) => (
  <Item
    aria-label={`${title} ${
      activeIndex === index ? "Hide the answer" : "Show the answer"
    }`}
    className="g-accordion-cards-card"
    tabIndex="0"
  >
    <Accordion.Toggle
      as={Item.Header}
      eventKey={index}
      onClick={toggleCardHandler(index)}
    >
      <div className="d-flex justify-content-between align-items-center">
        <Heading4 className="g-accordion-cards-card-title">{title}</Heading4>
        <img
          alt={title}
          src={`/images/blue-${activeIndex === index ? "minus" : "plus"}.svg`}
          width="30"
        />
      </div>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={index}>
      <Item.Body>
        <div className="g-accordion-paragraph">
          <Wysiwyg isAccordion={isAccordion} text={text} />
        </div>
      </Item.Body>
    </Accordion.Collapse>
  </Item>
)

Card.props = {
  index: PropTypes.string,
  text: PropTypes.shape(Wysiwyg.props),
  title: PropTypes.string,
}

Card.propTypes = {
  ...Card.props,
  toggleCardHandler: PropTypes.func,
}

export default Card
