import React, { useState } from "react"
import PropTypes from "prop-types"
import { Accordion, Card } from "react-bootstrap"
import "./accordion.scss"

const CustomAccordion = ({ cards, onClick }) => {
  const [activeId, setActiveId] = useState(cards[0].id)

  const toggleActive = id => {
    activeId === id ? setActiveId("") : setActiveId(id)
  }

  return (
    <Accordion defaultActiveKey={activeId} id="Accordion-wrapper">
      {cards.map((item, index) => (
        <Card className="tab" key={index}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={item.id}
            onClick={() => {
              toggleActive(item.id)
              if (onClick) onClick(item.id)
            }}
          >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="headline mb-0">{item.headline}</p>
              <img
                alt=""
                src={
                  activeId === item.id
                    ? "/images/minus-purple.svg"
                    : "/images/add-purple.svg"
                }
                width="30"
              />
            </div>
            <p className="subheadline">{item.subheadline}</p>
          </Accordion.Toggle>
          <Accordion.Collapse className="custome-collapse" eventKey={item.id}>
            <div>
              <Card.Body>
                <div
                  className="m-0"
                  dangerouslySetInnerHTML={{
                    __html: item.content.childMarkdownRemark.html,
                  }}
                />
              </Card.Body>
            </div>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  )
}

export const AccordionProps = {
  content: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
    content: PropTypes.string,
  }),
  headline: PropTypes.string,
  id: PropTypes.string,
  subheadline: PropTypes.string,
}

CustomAccordion.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(AccordionProps)),
  onClick: PropTypes.func,
}

export default CustomAccordion
