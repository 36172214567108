import React, { useState } from "react"
import PropTypes from "prop-types"
import Image from "components/elements/image"
import RichText from "components/elements/richText"
import "./doctorDetailedSection.scss"

const DoctorDetailedSection = ({ details, name, picture, role, type }) => {
  const [expanded, expandDetails] = useState(false)

  return (
    <div id="DoctorDetailedSection">
      {picture && (
        <div className="avatar">
          {type?.type === "link" ? (
            <Image image={picture.fluid} title={role} />
          ) : (
            <img alt={name} src={picture.fluid.src} />
          )}
        </div>
      )}
      {name && <h2 className="title">{name}</h2>}
      {role && <p className="subHeading">{role}</p>}
      {details && (
        <span
          className="expand"
          onClick={() => expandDetails(prev => !prev)}
          onKeyDown={() => expandDetails(prev => !prev)}
          role="button"
          tabIndex="0"
        >
          {expanded ? "Collapse Bio" : "Expand Bio"}
          {expanded ? (
            <i className="arrow up ml-2 mt-1" />
          ) : (
            <i className="arrow down ml-2 mb-1" />
          )}
        </span>
      )}
      {expanded && details && <RichText richText={details} textStyle="full" />}
      <hr className="line" />
    </div>
  )
}

DoctorDetailedSection.propTypes = {
  details: PropTypes.shape({
    json: PropTypes.object,
  }),
  name: PropTypes.string,
  picture: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  role: PropTypes.string,
  type: PropTypes.shape({
    type: PropTypes.string,
  }),
}

export default DoctorDetailedSection
